import axios from "axios";
import Papa from "papaparse";

export async function getQuestionText(setQuestionText) {
  return new Promise((resolve, reject) => {
    const response = axios
      .get(
        // "https://sheets.googleapis.com/v4/spreadsheets/1LW3F-gGJopIh28e7z5VOhpqYKwvebHdfHUZybyD5jks/values/Sheet1?majorDimension=ROWS&key=AIzaSyA58fRS2M2mQPVH3jYgsZ6XJfWI8glf3sk"
        "https://sheets.googleapis.com/v4/spreadsheets/1LW3F-gGJopIh28e7z5VOhpqYKwvebHdfHUZybyD5jks/values/QuestionText?majorDimension=ROWS&key=AIzaSyA58fRS2M2mQPVH3jYgsZ6XJfWI8glf3sk"
      )
      .then((res) => {
        let csvContent = res.data.values.map((e) => e.join("~")).join("\n");
        const results = Papa.parse(csvContent, { header: true, delimiter: "~" }); // object with { data, errors, meta }
        setQuestionText(results.data); // array of objects
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
}
